import React from 'react'
import { Box } from 'rebass'

export default function Translation({ size = 24, ...props }) {
  return (
    <Box
      as="svg"
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      viewBox="0 0 48 48"
      width={size}
      height={size}
      fill="currentcolor"
      {...props}
    >
      <title>Translation</title>
      <path d="M3.9,25.706h15.852c1.048,0,1.9-0.853,1.9-1.9v-10.26c0-1.047-0.853-1.899-1.9-1.899H3.9c-1.048,0-1.9,0.853-1.9,1.899  v10.26C2,24.854,2.853,25.706,3.9,25.706z M3,13.546c0-0.496,0.404-0.899,0.9-0.899h15.852c0.497,0,0.9,0.403,0.9,0.899v10.26  c0,0.498-0.404,0.9-0.9,0.9H3.9c-0.497,0-0.9-0.404-0.9-0.9V13.546z" />
      <path d="M44.1,22.294H28.248c-1.048,0-1.9,0.853-1.9,1.899v10.26c0,1.048,0.854,1.9,1.9,1.9H44.1c1.049,0,1.9-0.853,1.9-1.9v-10.26  C46,23.146,45.146,22.294,44.1,22.294z M45,34.453c0,0.497-0.404,0.9-0.9,0.9H28.248c-0.497,0-0.9-0.404-0.9-0.9v-10.26  c0-0.497,0.404-0.899,0.9-0.899H44.1c0.498,0,0.9,0.403,0.9,0.899V34.453z" />
      <path d="M4.81,16.216L6.646,14.8c0.147-0.113,0.354-0.114,0.501,0l1.28,0.986c0.506,0.391,1.215,0.391,1.722,0L11.43,14.8  c0.146-0.114,0.353-0.113,0.5,0l1.28,0.986c0.506,0.391,1.215,0.391,1.722,0l1.28-0.986c0.147-0.113,0.354-0.113,0.501,0  l1.836,1.416c0.091,0.069,0.198,0.104,0.305,0.104c0.15,0,0.298-0.066,0.396-0.195c0.168-0.218,0.128-0.531-0.091-0.701  l-1.835-1.416c-0.506-0.391-1.215-0.391-1.722,0l-1.28,0.987c-0.147,0.113-0.354,0.113-0.501,0l-1.28-0.987  c-0.507-0.391-1.216-0.391-1.722,0l-1.28,0.987c-0.147,0.113-0.354,0.113-0.501,0l-1.28-0.987c-0.507-0.391-1.216-0.391-1.722,0  L4.2,15.423c-0.219,0.17-0.259,0.483-0.091,0.701C4.277,16.343,4.591,16.382,4.81,16.216z" />
      <path d="M4.81,20.102l1.836-1.416c0.147-0.113,0.354-0.114,0.501,0l1.28,0.987c0.506,0.391,1.215,0.391,1.722,0l1.281-0.987  c0.146-0.114,0.353-0.113,0.5,0l1.28,0.987c0.506,0.391,1.215,0.391,1.722,0l1.28-0.987c0.147-0.113,0.354-0.113,0.501,0  l1.836,1.416c0.091,0.069,0.198,0.104,0.305,0.104c0.15,0,0.298-0.068,0.396-0.195c0.168-0.219,0.128-0.532-0.091-0.701  l-1.835-1.416c-0.506-0.391-1.215-0.391-1.722,0l-1.28,0.986c-0.147,0.113-0.354,0.113-0.501,0l-1.28-0.986  c-0.507-0.391-1.216-0.391-1.722,0l-1.28,0.986c-0.147,0.113-0.354,0.113-0.501,0l-1.28-0.986c-0.507-0.391-1.216-0.391-1.722,0  L4.2,19.31c-0.22,0.169-0.26,0.482-0.092,0.701C4.277,20.229,4.591,20.27,4.81,20.102z" />
      <path d="M23.994,15.155c0,0.01-0.006,0.018-0.006,0.027c0,0.003,0.003,0.005,0.003,0.009c0.008,0.083,0.04,0.16,0.088,0.229  c0.012,0.02,0.026,0.032,0.042,0.049c0.02,0.021,0.033,0.049,0.059,0.067c0.02,0.015,0.045,0.011,0.064,0.022  c0.025,0.016,0.043,0.043,0.072,0.054l3.553,1.276c0.258,0.092,0.546-0.043,0.64-0.303c0.093-0.26-0.042-0.545-0.302-0.64  l-2.4-0.862c2.014-0.752,4.202-0.678,6.164,0.209c1.969,0.891,3.475,2.494,4.239,4.519c0.076,0.2,0.267,0.323,0.468,0.323  c0.059,0,0.119-0.01,0.178-0.032c0.258-0.099,0.389-0.386,0.291-0.646c-1.695-4.49-6.582-6.852-11.112-5.492l1.163-1.5  c0.169-0.217,0.13-0.531-0.088-0.701c-0.219-0.17-0.532-0.129-0.702,0.088l-2.314,2.98c-0.011,0.016-0.014,0.033-0.024,0.049  c-0.012,0.02-0.02,0.037-0.028,0.059C24.008,15.011,23.992,15.083,23.994,15.155z" />
      <path d="M4.81,23.987l1.836-1.416c0.147-0.113,0.354-0.113,0.501,0l1.28,0.987c0.506,0.39,1.215,0.391,1.722,0l1.281-0.987  c0.146-0.113,0.353-0.113,0.5,0l1.28,0.987c0.506,0.39,1.215,0.391,1.722,0l1.28-0.987c0.147-0.113,0.354-0.113,0.501,0l1.836,1.416  c0.091,0.07,0.198,0.104,0.305,0.104c0.15,0,0.298-0.066,0.396-0.194c0.168-0.218,0.128-0.532-0.091-0.701l-1.835-1.416  c-0.506-0.392-1.215-0.392-1.722,0l-1.28,0.987c-0.147,0.113-0.354,0.113-0.501,0l-1.28-0.987c-0.507-0.392-1.216-0.392-1.722,0  l-1.28,0.987c-0.147,0.113-0.354,0.113-0.501,0l-1.28-0.987c-0.507-0.392-1.216-0.392-1.722,0L4.2,23.195  c-0.219,0.169-0.259,0.483-0.091,0.701C4.277,24.115,4.591,24.155,4.81,23.987z" />
      <path d="M43.203,26.337h-14.35c-0.276,0-0.5,0.225-0.5,0.5c0,0.276,0.224,0.5,0.5,0.5h14.35c0.275,0,0.5-0.224,0.5-0.5  C43.703,26.562,43.479,26.337,43.203,26.337z" />
      <path d="M43.203,28.823h-14.35c-0.276,0-0.5,0.225-0.5,0.5c0,0.276,0.224,0.5,0.5,0.5h14.35c0.275,0,0.5-0.224,0.5-0.5  C43.703,29.048,43.479,28.823,43.203,28.823z" />
      <path d="M43.203,31.31h-14.35c-0.276,0-0.5,0.224-0.5,0.5c0,0.275,0.224,0.5,0.5,0.5h14.35c0.275,0,0.5-0.225,0.5-0.5  C43.703,31.533,43.479,31.31,43.203,31.31z" />
      <path d="M20.131,31.11c-0.261-0.096-0.546,0.042-0.64,0.302c-0.094,0.261,0.042,0.546,0.302,0.64l2.398,0.863  c-4.169,1.553-8.828-0.562-10.4-4.728c-0.097-0.259-0.385-0.39-0.645-0.291c-0.258,0.098-0.389,0.386-0.291,0.646  c1.373,3.637,4.843,5.883,8.52,5.883c0.859,0,1.73-0.129,2.588-0.385l-1.159,1.492c-0.169,0.219-0.13,0.532,0.088,0.702  c0.091,0.071,0.199,0.104,0.306,0.104c0.149,0,0.297-0.065,0.396-0.192l2.313-2.979l0,0l0.002-0.002  c0.003-0.004,0.003-0.009,0.005-0.013c0.041-0.056,0.067-0.116,0.082-0.183c0.005-0.022,0.004-0.045,0.006-0.067  c0.004-0.049,0.003-0.097-0.007-0.146c-0.003-0.014,0.001-0.025-0.002-0.039c-0.004-0.015-0.018-0.024-0.021-0.039  c-0.009-0.021-0.01-0.043-0.021-0.063c-0.013-0.023-0.033-0.038-0.05-0.058c-0.017-0.022-0.031-0.042-0.051-0.063  c-0.039-0.036-0.082-0.063-0.129-0.084c-0.015-0.007-0.025-0.021-0.041-0.026L20.131,31.11z" />
    </Box>
  )
}

import React from 'react'
import { Heading, Text, Box, Card, Button, Link as RawLink } from 'rebass'
import { graphql, useStaticQuery } from 'gatsby'
import BackgroundImage from 'gatsby-background-image'
import Image from 'gatsby-image'

import Layout from '../components/layout'
import SEO from '../components/seo'
import Subtitles from '../components/icons/subtitles'
import Translation from '../components/icons/translation'
import Copywriting from '../components/icons/copywriting'
import Localisation from '../components/icons/localisation'
import Quotes from '../components/icons/quotes'
import Headline from '../components/headline'
import Hr from '../components/hr'
import Link from '../components/link'

const ServiceCard = props => (
  <BackgroundImage backgroundColor="#292225" fluid={props.backgroundImageData}>
    <Card
      display="flex"
      flexDirection="column"
      alignItems="center"
      paddingX="3"
      paddingY="5"
      backgroundColor="rgba(0, 0, 0, 0.7)"
      width="100%"
      height="100%"
      sx={{ position: 'relative' }}
    >
      <Link
        to={props.href}
        sx={{
          position: 'absolute',
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
        }}
        ariaLabel={props.heading}
      />
      {props.icon}
      <Heading
        as="h4"
        sx={{
          color: 'white',
          fontFamily: 'sans-serif',
          fontSize: '3',
          fontWeight: 'lighter',
          textTransform: 'uppercase',
          paddingBottom: '3',
        }}
      >
        {props.heading}
      </Heading>
      <Text color="white" textAlign="center" fontFamily="sans-serif" fontSize="2">
        {props.summary}
      </Text>
    </Card>
  </BackgroundImage>
)

const Testimonial = props => (
  <Box
    sx={{
      display: 'grid',
      gridTemplateColumns: ['minmax(auto, 400px)', 'minmax(auto, 400px) minmax(auto, 400px)'],
      gridGap: '4rem',
      direction: props.direction,
    }}
  >
    <Box display="flex" flexDirection="column" justifyContent="center" sx={{ direction: 'ltr' }} px={[null, null, 5]}>
      <RawLink href={props.companyUrl} mx="auto" width={100} mb={3}>
        {props.logoImage}
      </RawLink>
      <Text fontSize="2" textAlign="justify" mb={4} mr={4}>
        <Quotes size={50} color="gold" />
        <em>{props.quote}</em>
      </Text>
      <Text textAlign="right">
        <Text color="gold" fontSize="4" fontWeight="600">
          - {props.authorName}
        </Text>
        {props.authorTitle}
      </Text>
    </Box>
    <Box width="100%" height="600px" display={['none', 'block']}>
      <BackgroundImage fluid={props.decorativeImageData} style={{ height: '100%', width: '100%' }} />
    </Box>
  </Box>
)

const IndexPage = () => {
  const data = useStaticQuery(graphql`
    query {
      headerBackgroundImage: file(relativePath: { eq: "furn-in-snow.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      subtitlingImage: file(relativePath: { eq: "subtitling.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      copywritingImage: file(relativePath: { eq: "copywriting.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      localisationImage: file(relativePath: { eq: "localisation.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      translationImage: file(relativePath: { eq: "translation.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      tenGroupLogo: file(relativePath: { eq: "ten-group-logo.png" }) {
        childImageSharp {
          fluid(maxWidth: 400) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      take1Logo: file(relativePath: { eq: "take1-logo.png" }) {
        childImageSharp {
          fluid(maxWidth: 400) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      firstTestimonialImage: file(relativePath: { eq: "cone-in-forest.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      secondTestimonialImage: file(relativePath: { eq: "projector.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <Layout>
      <SEO title="Home" />
      <Headline fluidBackgroundImage={data.headerBackgroundImage.childImageSharp.fluid}>
        <Heading variant="headline" as="h1">
          ISR Translations
        </Heading>
        <Hr color="gold" />
        <Heading as="h3" fontWeight="lighter" fontSize={[3, null, 5]} mb="3">
          <em>Idunn Sofie Riise &mdash; Norwegian translator and linguist, helping you make your content local.</em>
        </Heading>
        <Link
          as={Button}
          variant="primary"
          to="/contact#get-a-quote"
          backgroundColor="gold"
          width={['100%', 'unset']}
          fontWeight="bold"
        >
          Get a quote
        </Link>
      </Headline>

      <Box display="flex" justifyContent="center" mx={4} mt={6}>
        <Testimonial
          quote="I very much appreciate Idunn’s effort in translating and localizing our content for our Norwegian audience. I was impressed with her work and I appreciate that her translation was as accurate as it needed to be. I would like to thank her for that."
          authorName="Ahmad Illo"
          authorTitle="Content Marketing Specialist and Head of Translation"
          logoImage={
            <Image fluid={data.tenGroupLogo.childImageSharp.fluid} alt="ten-group-logo" title="Ten Lifestyle Group" />
          }
          companyUrl="https://www.tenlifestylegroup.com/"
          decorativeImageData={data.firstTestimonialImage.childImageSharp.fluid}
        />
      </Box>

      <Box
        backgroundColor="#e1dbdd"
        marginTop="5"
        height="25rem"
        sx={{ clipPath: [null, 'polygon(0 0, 100% 16%, 100% 100%, 0% 100%)'] }}
      />
      <Box
        sx={{
          position: 'relative',
          marginTop: '-17rem',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Heading fontFamily="sans-serif" fontSize="5" fontWeight="lighter" letterSpacing="5px" textAlign="center">
          Professional Services
        </Heading>
        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: ['1fr', '1fr 1fr', null, '1fr 1fr 1fr 1fr'],
            gridGap: '0.25rem',
            m: 4,
          }}
        >
          <ServiceCard
            icon={<Translation size={80} color="gold" mb={4} mt="-14px" />}
            heading="Translation"
            href="/services#translation"
            summary="Let your ideas and information cross borders post-haste."
            backgroundImageData={data.translationImage.childImageSharp.fluid}
          />
          <ServiceCard
            icon={<Subtitles size={64} color="gold" mb={4} />}
            heading="Subtitling"
            href="/services#subtitling"
            summary="High quality subtitles are effortless to read, without getting in the way."
            backgroundImageData={data.subtitlingImage.childImageSharp.fluid}
          />
          <ServiceCard
            icon={<Localisation size={64} color="gold" mb={4} />}
            heading="Localisation"
            href="/services#localisation"
            summary="By localising your content, you make sure nothing is lost in translation."
            backgroundImageData={data.localisationImage.childImageSharp.fluid}
          />
          <ServiceCard
            icon={<Copywriting size={64} color="gold" mb={4} />}
            heading="Copywriting"
            href="/services#copywriting"
            summary="Build on your localisation with content created for the local culture."
            backgroundImageData={data.copywritingImage.childImageSharp.fluid}
          />
        </Box>
      </Box>

      <Box
        sx={{
          position: 'relative',
          display: 'flex',
          justifyContent: 'center',
          px: 4,
          pb: 3,
          mt: 6,
          mb: 6,
        }}
      >
        <Testimonial
          quote="Great translator who was very efficient and good with communication. Will definitely use again for similar jobs."
          authorName="Paris Collins"
          authorTitle="Production Supervisor"
          logoImage={
            <Image fluid={data.take1Logo.childImageSharp.fluid} alt="take1-logo" title="Take1 transcription" />
          }
          companyUrl="https://www.take1.tv/"
          decorativeImageData={data.secondTestimonialImage.childImageSharp.fluid}
          direction="rtl"
        />
      </Box>
    </Layout>
  )
}
export default IndexPage

import React from 'react'
import { Box } from 'rebass'

export default function Quotes({ size = 24, ...props }) {
  return (
    <Box
      as="svg"
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      viewBox="0 0 100 100"
      width={size}
      height={size}
      fill="currentcolor"
      {...props}
    >
      <title>Quotes</title>
      <g xmlns="http://www.w3.org/2000/svg">
        <path d="M48.87,42.353c0-11.276-9.14-20.417-20.417-20.417c-11.277,0-19.462,8.826-20.417,20.062   c-1.458,17.145,11.567,31.5,27.38,36.067c-5.39-3.259-9.15-8.927-9.777-15.519c0.923,0.131,1.857,0.224,2.814,0.224   C39.73,62.77,48.87,53.63,48.87,42.353z" />
        <path d="M92,42.353c0-11.276-9.14-20.417-20.418-20.417c-11.276,0-19.462,8.826-20.418,20.062   c-1.458,17.145,11.567,31.5,27.381,36.067c-5.39-3.259-9.149-8.927-9.777-15.519c0.923,0.131,1.858,0.224,2.814,0.224   C82.86,62.77,92,53.63,92,42.353z" />
      </g>
    </Box>
  )
}

import React from 'react'
import { Box } from 'rebass'

export default function Subtitles({ size = 24, ...props }) {
  return (
    <Box
      as="svg"
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      width={size}
      height={size}
      fill="currentcolor"
      viewBox="0 0 100 100"
      {...props}
    >
      <title>Subtitles</title>
      <g>
        <path d="M89.998,7.506H10.002c-5.522,0-10,4.478-10,9.999v64.99c0,5.521,4.477,9.999,10,9.999h79.996c5.522,0,10-4.478,10-9.999   v-64.99C99.998,11.983,95.521,7.506,89.998,7.506z M94.998,82.495c0,2.753-2.241,4.999-5,4.999H10.002c-2.758,0-5-2.246-5-4.999   v-64.99c0-2.754,2.241-4.999,5-4.999h79.996c2.759,0,5,2.245,5,4.999V82.495z M64.999,67.497H25.001v-4.999h39.998V67.497z    M74.999,67.497h-5v-4.999h5V67.497z M15.001,72.497h5v4.999h-5V72.497z M25.001,72.497H60v4.999H25.001V72.497z M64.999,72.497   h19.999v4.999H64.999V72.497z" />
      </g>
    </Box>
  )
}

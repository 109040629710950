import React from 'react'
import { Box } from 'rebass'

export default function Copywriting({ size = 24, ...props }) {
  return (
    <Box
      as="svg"
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      width={size}
      height={size}
      fill="currentcolor"
      viewBox="0 0 50 50"
      {...props}
    >
      <title>Copywriting</title>
      <g>
        <path d="M8.6,49h30.7c3.2,0,5.8-2.6,5.8-5.9v-7.3c0-1.1-0.9-2-1.9-2h-7.7v-5.1c1.3-1.6-4.7,5.7,12.8-15.6c1.1-1.3,0.9-3.2-0.4-4.3   l-2.6-2.2c-1.2-1-3.2-0.9-4.2,0.4c-0.1,0.1-5.9,7.2-5.6,6.8V3c0-1.1-0.9-2-1.9-2H2.9C1.9,1,1,1.9,1,3v38.3C1,45.5,4.4,49,8.6,49z    M43.1,35.8v7.3c0,2.2-1.7,3.9-3.8,3.9c-2.1,0-3.8-1.7-3.8-3.9v-7.3L43.1,35.8z M22.9,33.1l3.3,2.8l-4.4,2L22.9,33.1z M39.4,12.1   l1.3,1.1L25,32.3l-1.3-1.1L39.4,12.1z M27.9,34.8l-1.3-1.1l15.7-19.1l1.3,1.1L27.9,34.8z M42.6,8.3c0.4-0.4,1-0.5,1.4-0.1l2.6,2.2   c0.4,0.4,0.5,1,0.1,1.5l-1.9,2.3l-4.2-3.6L42.6,8.3z M2.9,3l30.5,0v13.2L29,21.6c0,0,0,0-0.1,0H7.8c-0.6,0-1,0.4-1,1   c0,0.6,0.4,1,1,1h19.6L25,26.4H7.8c-0.6,0-1,0.4-1,1c0,0.6,0.4,1,1,1h15.6l-1.9,2.3c0,0,0,0,0,0c0,0.1-0.1,0.1-0.1,0.2   c0,0.1-0.1,0.1-0.1,0.2c0,0,0,0,0,0l-0.1,0.2H7.8c-0.6,0-1,0.4-1,1c0,0.6,0.4,1,1,1h13l-1.5,6.1c-0.1,0.4,0,0.8,0.3,1   c0.3,0.3,0.7,0.3,1,0.1l7.7-3.5c0,0,0,0,0,0c0.1,0,0.1-0.1,0.2-0.1c0.1,0,0.1-0.1,0.2-0.1c0,0,0,0,0,0l4.7-5.7v12   c0,1.5,0.6,2.9,1.5,3.9H8.6C5.5,47,3,44.4,3,41.3L2.9,3z" />
        <path d="M7.8,13.8h10.8c0.6,0,1-0.4,1-1c0-0.6-0.4-1-1-1H7.8c-0.6,0-1,0.4-1,1C6.8,13.4,7.2,13.8,7.8,13.8z" />
        <path d="M7.8,18.7H29c0.6,0,1-0.4,1-1c0-0.6-0.4-1-1-1H7.8c-0.6,0-1,0.4-1,1C6.8,18.2,7.2,18.7,7.8,18.7z" />
      </g>
    </Box>
  )
}
